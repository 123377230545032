import React from "react";
import { NavLink } from 'react-router-dom';

import logoImalusit from "../assets/img/logoImalusit.png";


const Header = () => {
    return (
        <header className="headerContainer" style={{ backgroundColor: '#00866e', maxWidth: '1280px', margin: 'auto'}}>
            <div style = {{position: 'relative', display: 'flex', alignContent: 'center', justifyContent: 'center', height: '80px'}}>
                 <a href="/" >
                    <img 
                        src={logoImalusit} 
                        alt="Logo" 
                        className="logo" 
                        style={{ height: '60px', position: 'absolute', top: '10px', left: '70px' }} 
                        cursor="pointer"
                    />
                </a>
                <nav className="navbar" style={{margin: 'auto'}}>
                    <ul className="navbar-nav d-flex flex-row fw-bold">
                        <li className="nav-item mx-3">
                            <NavLink 
                                to="/Home" 
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'bg-danger' : ''}`}
                                style={{ paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px' }}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item mx-3">
                            <NavLink 
                                to="/Contacto" 
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'bg-danger' : ''}`}
                                style={{ paddingLeft: '10px', paddingRight: '10px', borderRadius: '5px' }}
                            >
                                Contacto
                            </NavLink>
                        </li>
                        <li className="nav-item mx-3">
                            <NavLink 
                                to="/Produtos" 
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'bg-danger' : ''}`}
                                style={{ paddingLeft: '10px', paddingRight: '10px', borderRadius: '5px' }}
                            >
                                Produtos
                            </NavLink>
                        </li>
                    </ul>
                </nav>

            </div>
        </header>
    );
};

export default Header;