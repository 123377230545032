import React from 'react';

const NotFound = () => {
  return (
    <div style={{width: '1280px', margin: '0 auto', textAlign: 'center', padding: '50px', display: 'flex', flexDirection: 'column', 
                    justifyContent: 'center', alignItems: 'center', height: '70vh', backgroundColor: '#e9e6df'}}>
      <h1 style={{fontSize: '300px', color: '#007961'}}>404</h1>
      <p style={{fontSize: '30px', color: '#007961'}} >Página não encontrada</p>
      <a href="/" style={{fontSize: '30px', color: '#007961', textDecoration: 'none'   }}>
        Voltar para a página inicial
      </a>
    </div>
  );
};

export default NotFound;
