import React from 'react';
import imgImalust from '../assets/img/ImalusitFoto.png';

const lightBrown = '#e9e6df';
const redBtn = '#D0333A';
const darkGreen = '#007961';

const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
        const response = await fetch('https://www.imalusit.pt/scripts/sendEmail.php', {
            method: 'POST',
            body: formData,
        });

        const result = await response.json();

        if (result.status === 'success') {
            alert(result.message);
        } else {
            alert(result.message);
        }
    } catch (error) {
        console.error('Erro:', error);
        alert('Erro ao processar sua solicitação.');
    }

    window.location.reload();
};
    

const contacto = () => {
    return (
        <div style={{ maxWidth: '1280px', margin: '0 auto' }}>
            <div style={{ width: '100%', height: '690px', padding: '0px 97px', margin: '0 auto', background: lightBrown, marginTop: '-50px' }}>
                <div className='my-5'>
                    <div className='row' style={{ justifyContent: 'center', alignItems: 'center', height: '100%', padding: '66px 0' }}>
                        <div className="col-md-6" style={{ border: `2px solid ${darkGreen}`, padding: '50px 100px', display: 'flex', flexDirection: 'column', alignItems: 'left', height: '556px', width: '448px', backgroundColor: 'white', justifyContent: 'center' }}>
                            <h2 style={{ fontFamily: 'Concord' }}>Contate-nos</h2>
                            <p style={{ color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px', marginTop: '10px' }}>
                                Ficaremos encantados em fazer parte do seu projeto.
                            </p>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
                                    <label htmlFor="nome" style={{ color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px' }}>Nome</label>
                                    <input type="text" className="form-control" id="nome" name="nome" style={{ fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px' }} />

                                    <label htmlFor="email" style={{ color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px', marginTop: '10px' }}>Email</label>
                                    <input type="email" className="form-control" id="email" name="email" style={{ fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px' }} />

                                    <label htmlFor="telefone" style={{ color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px', marginTop: '10px' }}>Telefone</label>
                                    <input type="tel" className="form-control" id="telefone" name="telefone" style={{ fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px' }} />

                                    <label htmlFor="mensagem" style={{ color: darkGreen, fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px', marginTop: '10px' }}>Assunto</label>
                                    <textarea className="form-control" id="mensagem" name="mensagem" rows="3" style={{ fontFamily: 'Concord', fontSize: '18px', fontWeight: '400', lineHeight: '24px' }}></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary" style={{ margin: '20px 0', backgroundColor: redBtn, border: 'none', borderRadius: '5px', padding: '0px 30px', color: '#fff', fontWeight: 'bold', fontSize: '16px', cursor: 'pointer' }}>Enviar</button>
                            </form>
                        </div>

                        <div className="col-md-6 d-flex align-items-center justify-content-center" style={{ paddingLeft: '0' }}>
                            <img src={imgImalust} alt="Formulário" style={{ width: '558px', height: '556px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default contacto;
